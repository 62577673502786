/* Header Section with Background Image */
.header-section {
    position: relative;
    background-image: url('./Image/diabetesbanner.png'); /* Replace with your actual image path */
    background-size: cover;
    background-position: center;
    height: 250px; /* Adjust as necessary */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; /* Ensure text is visible */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(1, 75, 84, 0.5); /* Semi-transparent overlay */
  }
  
  .container {
    position: relative;
    z-index: 2; /* Ensures content is above overlay */
  }
  
  h1 {
    font-size: 36px;
    color: white; /* Make the title visible */
  }
  
  .lead {
    font-size: 18px;
    color: white;
  }
  
  /* About Dhaka Cast Section */
  .about-section {
    margin-top: 50px;
    padding: 20px 0;
  }
  
  .about-section h2 {
    font-size: 28px;
    color: #014B54;
  }
  
  .about-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  
  .about-section img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Team Section */
  .team-section {
    margin-top: 50px;
  }
  
  .team-section h2 {
    text-align: center;
    color: #069aad;
    margin-bottom: 30px;
  }
  
  .team-section .team-member {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .team-section .team-member img {
    width: 150px;
    height: 180px;
    object-fit: fill;
    border-radius: 10%;
    margin-bottom: 15px;
  }
  
  .team-section .team-member h5 {
    font-size: 18px;
    color: #001012;
  }
  
  .team-section .team-member p {
    font-size: 14px;
    color: #3a3939;
  }
  
  /* Achievements Section */
  .achievements-section {
    margin-top: 50px;
  }
  
  .achievements-section h2 {
    text-align: center;
    color: #014B54;
    margin-bottom: 30px;
  }
  
  .achievements-section p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }
  
  .achievements-section img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Vision Section */
  .vision-section {
    margin-top: 50px;
    text-align: center;
  }
  
  .vision-section h2 {
    font-size: 28px;
    color: #014B54;
    margin-bottom: 20px;
  }
  
  .vision-section p {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }
  
  .text-style {
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 1.5;
    color: #333333;
    text-align: justify; /* Add this to justify the text */
  }
  .text-style-doctor{
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 1.5;
    color: #343232;
    text-align: justify; 
  }

  .text-style-doctorItem{
    
    font-family: 'Roboto';
    line-height: 1.5;
    color: #242323;
    text-align: center; 
  }
