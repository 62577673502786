.statistic-section {
  position: relative;
  background-image: url('../Image/diabetesbanner.png');
  background-size: cover;
  background-position: center;
  height: 250px;
  display: flex;
  align-items: center;
}

.statistic-section .container {
  max-width: 100%;
}

.statistic-section .row {
  width: 100%;
  display: flex;
  justify-content: center; /* Center all columns in the row */
}

.statistic-section .col {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical centering */
  align-items: center;     /* Horizontal centering */
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .statistic-section {
  position: relative;
  background-image: url('../Image/diabetesbanner.png');
  background-size: cover;
  background-position: center;
  height: 350px;
  display: flex;
  align-items: center;
}
  .statistic-section .row {
    display: flex;
    flex-wrap: wrap;
    height: 50px;
  }

  .statistic-section .col {
    flex: 0 0 50%; /* 2 columns for mobile */
    max-width: 50%;
  }
}

@media (min-width: 769px) {
  .statistic-section .col {
    flex: 0 0 25%; /* 4 columns for larger screens */
    max-width: 25%;
  }
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 75, 84, 0.4); /* Solid color with 40% transparency */
}


/* Full-width background with wave image and overlay */
.wave-background {
  position: relative;
  width: 100%;
  height: 750px; /* Adjust height as needed */
  background-image: url('../Image/diabetesbanner.png'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}



.gradient-custom {
  background: radial-gradient(50% 123.47% at 50% 50%, #034f60 0%, #023e3d 100%),
    linear-gradient(121.28deg, #4b037a 0%, #034f60 100%),
    linear-gradient(360deg, #4b037a 0%, #023e3d 100%),
    radial-gradient(100% 164.72% at 100% 100%, #034f60 0%, #023b47 100%),
    radial-gradient(100% 148.07% at 0% 0%, #034f60 0%, #33022d 100%);
background-blend-mode: screen, color-dodge, overlay, difference, normal;



}
.carousel-indicators {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.btn .service-button .text-white .mt-3:hover {   
  background-color: #A4449D;
  
}

.slider-container {
  width: 100%;
  padding: 20px;
}

.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

